import React, { Component } from 'react'
import { connect } from 'react-redux'
import SEO from '@/components/SEO/SEO'
import FeedHeader from '@/components/FeedHeader/FeedHeader'
import PostThumb from '@/components/PostThumb/PostThumb'
import OrgThumb from '@/components/OrgThumb/OrgThumb'

import ContentGrid from '@/components/ContentGrid/ContentGrid'
import Pagination from '@/components/Pagination/Pagination'
import { prepItem, createPaginationLinks } from '@/utils'
import { setupPage } from '@/utils/common'

class Tags extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			pageContext: {
				group,
				index,
				first,
				last,
				pageCount,
				pathPrefix,
				additionalContext: { title, total }
			},
			location
		} = this.props

		const entries = prepItem(group)

		const { pages, previousUrl, nextUrl } = createPaginationLinks({
			index,
			pageCount,
			pathPrefix
		})

		return (
			<div className="pb-2">
				<SEO
					title={title}
					description={`your search returned ${total} results for ${title}`}
					socialTitle={`your search returned ${total} results for ${title}`}
					socialDesc={`your search returned ${total} results for ${title}`}
					url={location.href}
				/>
				<FeedHeader title={`#${title}`}>
					<p className="text-xl-fluid">
						your search returned {total} results for{' '}
						<span className="text-orange">“{title}”</span>
					</p>
				</FeedHeader>
				<ContentGrid
					content={entries}
					render={item =>
						item.type === 'directory' ? (
							<OrgThumb {...item} />
						) : (
							<PostThumb {...item} />
						)
					}
				/>

				{pages.length > 1 && (
					<div className="wrapper">
						<Pagination
							prev={`${pathPrefix}/${previousUrl}`}
							next={`${pathPrefix}/${nextUrl}`}
							first={first}
							last={last}
							pages={pages}
						/>
					</div>
				)}
			</div>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(Tags)
